import React from 'react'
import Link from 'gatsby-link'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import Helmet from 'react-helmet'
import LazyLoad from 'react-lazyload'
import { graphql } from 'gatsby'
import Layout from "../components/layout"

class FaqIndex extends React.Component {
  render() {
    const site = get(this, 'props.data.site.siteMetadata')
    const posts = get(this, 'props.data.remark.posts')
    const questions = []

    posts.forEach((data, i) => {
      const layout = get(data, 'post.frontmatter.layout')
      const path = get(data, 'post.path')
      const title = get(data, 'post.frontmatter.title')
      const html = get(data, 'post.html')
      if (layout === 'post' && path !== '/404/') {
        questions.push(
          <li>
            <input type="checkbox" defaultChecked />
            <i></i>
            <h3>{title}</h3>
            <p
              className="faq-content"
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </li>
        )
      }
    })

    return (
      <Layout location={this.props.location}>
        <Helmet
          title={get(site, 'title')}
          meta={[
            { name: 'twitter:card', content: 'summary' },
            { name: 'twitter:site', content: `@${get(site, 'twitter')}` },
            { property: 'og:title', content: get(site, 'title') },
            { property: 'og:type', content: 'website' },
            { property: 'og:description', content: get(site, 'description') },
            { property: 'og:url', content: get(site, 'url') },
            {
              property: 'og:image',
              content: `${get(site, 'url')}/img/logo.png`,
            },
          ]}
        />
        <div className="questions">
          <div className="container">
            <div className="row">
              <div className="col-4"><h1 className="underlined">Foire aux questions</h1></div>
              <div className="col-8"></div>
            </div>
            <div className="accordion">
              <ul>
                {questions}
              </ul>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default FaqIndex

export const pageQuery = graphql`
  query FaqQuery {
    site {
      siteMetadata {
        title
        description
        url: siteUrl
        author
        twitter
      }
    }
    remark: allMarkdownRemark(
      filter: {
        frontmatter: {
          type:{eq : "faq"}
        }
      },
      sort: {
        fields: [fileAbsolutePath],
        order: ASC
      }
    ) {
      posts: edges {
        post: node {
          fileAbsolutePath
          html
          excerpt(pruneLength: 300)
          frontmatter {
            layout
            title
            path
            categories
            type
          }
        }
      }
    }
  }
`
